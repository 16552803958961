"use strict";

jQuery(document).ready(function ($) {
  var topNavbar = $('.top-nav');
  var stickyNavbar = $('.main-nav');
  var prevScrollPos = $(window).scrollTop();
  if ($('.top-nav').length) {
    if (window.matchMedia("(max-width: 1350px)").matches) {
      topNavbar.css('top', '-40px');
      stickyNavbar.css('margin-top', '0');
    } else {
      $(window).scroll(function () {
        var currentScrollPos = $(window).scrollTop();
        if (prevScrollPos > currentScrollPos) {
          topNavbar.css('top', '0');
          stickyNavbar.css('margin-top', '40px');
        } else {
          topNavbar.css('top', '-40px');
          stickyNavbar.css('margin-top', '0');
        }
        prevScrollPos = currentScrollPos;
      });
    }
  }
  var searchModal = new bootstrap.Modal(document.getElementById('searchModal'));
  // Event listener for the search button click that opens the modal
  $('.search-button-nav').click(function () {
    $('body').removeClass('scrolling');
    $('.main-nav').removeClass('scroll');
    $('.top-nav').removeClass('scroll');
    $('.logo-svg.logo-dark').removeClass('active');
    $('.logo-svg.logo-light').addClass('active');
    $('.search-icon').removeClass('active');
    // $(".site-header").removeClass('site-header--light');
    $(".mobile-site-header").removeClass('show');
    $('#closeModalButton').css('filter', '');
    if ($('.site-header').hasClass('site-header--light')) {
      $('.logo-svg.logo-dark').addClass('active');
      $('.logo-svg.logo-light').removeClass('active');
    }

    // new addition to fully close the nav when search modal is being opened
    $('.site-header .navbar-collapse').removeClass('show');
    $('.mobile-site-header .navbar-collapse').removeClass('show');
    $('.navbar-toggler').attr('aria-expanded', false);
    $("html").removeClass("stop-scroll");
  });
  var myModal = document.getElementById('searchModal');
  var myInput = document.getElementById('search-bar');
  myModal.addEventListener('show.bs.modal', function () {
    // myInput.focus() // disabled on 13/12/2023

    console.log('#searchModal show.bs.modal');
    var currentOpenNavDropdown = $('.menu-dropdown.active');
    if (currentOpenNavDropdown && currentOpenNavDropdown.length > 0) {
      console.log(currentOpenNavDropdown);
      currentOpenNavDropdown.removeClass('active');
      currentOpenNavDropdown.removeClass('open');
      $('.parent-active').removeClass('parent-active');
      $('.menu-overlay').removeClass('show');
      $('.nav-link').removeClass('open');
      $('.menu-overlay').removeClass('show');
      $('.nav-item.open').removeClass('open');
      currentOpenNavDropdown.fadeOut(100, 'easeOutCubic', function () {
        // After sliding up, remove the 'active' class
        $(this).removeClass('active');
      });
    }
  });
  myModal.addEventListener('hidden.bs.modal', function () {
    console.log('after hiding search modal');
    window.dispatchEvent(new Event('scroll'));
    setTimeout(function () {
      window.dispatchEvent(new Event('scroll'));
    }, 100);
  });

  // Event listener for the search button click that closes the modal
  $('button.close-in-modal, button.close-in-navbar').click(function () {
    $("#searchModal").modal('hide'); // Hide the modal

    if ($("#search-results").hasClass('open')) {
      $("#search-results").addClass('open');
    }

    // if nav is open
    if ($('body').hasClass('opened-nav-toggler') || $(".site-header").hasClass('show') || $(".mobile-site-header").hasClass('show')) {}

    // Check if the page is not at the top when the modal is closed
    if (isPageNotAtTop()) {
      // Add classes if the page is not at the top
      $('body').addClass('scrolling');
      $('.main-nav').addClass('scroll');
      $('.top-nav').addClass('scroll');
      $('.logo-svg.logo-dark').addClass('active');
      $('.logo-svg.logo-light').removeClass('active');
      $('.search-icon').addClass('active');
    }

    // if( $('.site-header').hasClass('site-header--light') ) {
    //     $('.main-nav').addClass('scroll');
    //     $('.logo-svg.logo-dark').addClass('active');
    //     $('.logo-svg.logo-light').removeClass('active');
    // }else{
    //     if( isPageNotAtTop() ) {
    //         $('.main-nav').addClass('scroll');
    //         $('.logo-svg.logo-dark').addClass('active');
    //         $('.logo-svg.logo-light').removeClass('active');
    //     }else{
    //         $('.main-nav').removeClass('scroll');
    //         $('.logo-svg.logo-dark').removeClass('active');
    //         $('.logo-svg.logo-light').addClass('active');
    //     }
    // }

    console.log('close search modal');
    window.dispatchEvent(new Event('scroll'));
  });
  $('.dropdown-nav-item').click(function () {
    $('#closeModalButton').css('filter', 'invert(1)');
  });

  // Function to check if page is not at the top
  function isPageNotAtTop() {
    return $(window).scrollTop() > 0;
  }
  function applyStyleWhenAtTop(noActive) {
    var currentScrollPos = $(window).scrollTop();
    var isModalOpen = $('body').hasClass('modal-open');
    if (isModalOpen) {
      // Do nothing if a modal is open
      return;
    }
    if (currentScrollPos === 0 && !$('.menu-dropdown').hasClass('active') || currentScrollPos === 0 && noActive === true) {
      // Add your CSS style for when the page is at the top
      topNavbar.removeClass('scroll');
      stickyNavbar.removeClass('scroll');
      $('body').removeClass('scrolling');
      $('.logo-svg.logo-dark').addClass('active');
      $('.logo-svg.logo-light').removeClass('active');
      $('.search-icon').removeClass('active');

      // if no .site-header--light and .herobanner__image exists
      // change logo to light mode
      if (!$('.site-header').hasClass('.site-header--light') && $('.herobanner__image').length > 0) {
        $('.logo-svg.logo-dark').removeClass('active');
        $('.logo-svg.logo-light').addClass('active');
      }
    } else {
      // Reset the CSS style when not at the top
      topNavbar.addClass('scroll');
      stickyNavbar.addClass('scroll');
      $('body').addClass('scrolling');
      $('.logo-svg.logo-dark').addClass('active');
      $('.logo-svg.logo-light').removeClass('active');
      $('.search-icon').addClass('active');
    }
  }
  applyStyleWhenAtTop(); // Call the function on page load

  //Shrinks the nav if not at the top of the page.
  $(window).scroll(throttle(function (event) {
    var isModalOpen = $('body').hasClass('modal-open');
    if (isModalOpen) {
      // Do nothing if a modal is open
      return;
    }
    if (window.scrollY > 0) {
      $('.mobile-site-header').addClass('scrolling');
    } else {
      $('.mobile-site-header').removeClass('scrolling');
    }
  }, 250));
  if (window.scrollY > 0) {
    var isModalOpen = $('body').hasClass('modal-open');
    if (isModalOpen) {
      // Do nothing if a modal is open
      return;
    }
    $('.mobile-site-header').addClass('scrolling');
  } else {
    $('.mobile-site-header').removeClass('scrolling');
  }
  $(window).scroll(function () {
    if (!$('.mobile-site-header').hasClass('show')) {
      applyStyleWhenAtTop(); // Call the function on scroll
    }
  });

  $('.navbar-toggler').click(function () {
    $(this).attr('aria-expanded', function (i, attr) {
      return attr == 'true' ? 'false' : 'true';
    });
    $('body').toggleClass('opened-nav-toggler');
    if ($('.site-header').hasClass('site-header--light')) {
      $('.logo-svg.logo-dark').addClass('active');
      $('.logo-svg.logo-light').removeClass('active');
    }
  });
  function openHeader() {
    topNavbar.addClass('scroll');
    stickyNavbar.addClass('scroll');
    $('.logo-svg.logo-dark').addClass('active');
    $('.logo-svg.logo-light').removeClass('active');
    $('.search-icon').addClass('active');
    topNavbar.css('top', '0');
    stickyNavbar.css('margin-top', '40px');
    if ($('.site-header').hasClass('site-header--light')) {
      $('.logo-svg.logo-dark').addClass('active');
      $('.logo-svg.logo-light').removeClass('active');
    }
  }
  var openDropdowns = [];

  // Function to close all open dropdowns
  function closeAllDropdowns() {
    openDropdowns.forEach(function (dropdown) {
      dropdown.slideUp(300, 'easeOutCubic', function () {
        // After sliding up, remove the 'active' class
        $(this).removeClass('active');
      });
    });
    openDropdowns = [];
    // Remove the 'active' class from all .menu-dropdown elements
    $('.menu-dropdown').removeClass('active');
    $('.menu-overlay').removeClass('show');
    $('.nav-link').removeClass('open');
  }
  $('.parent-active:first').addClass('current-active');
  if (window.innerWidth > 1350) {
    // Initialize the currently open dropdown
    var currentDropdown = null;
    $('.dropdown-nav-item .nav-link').click(function (e) {
      e.preventDefault(); // Prevent the default action (e.g., following the link)
      openHeader();
      $("#searchModal").modal('hide'); // Hide the modal

      $('.nav-link').removeClass('parent-active');
      var menuDropdown = $(this).parent().find('.menu-dropdown');
      var dropdownLink = $(this).closest('.nav-link');
      var navItem = dropdownLink.parent();
      dropdownLink.addClass('parent-active');

      // Check if a different menu-dropdown is already open
      if (currentDropdown && currentDropdown[0] !== menuDropdown[0]) {
        currentDropdown.removeClass('active');
        $('.menu-overlay').removeClass('show');
        $('.nav-link').removeClass('open');
        $('.nav-item.open').removeClass('open');
        currentDropdown.fadeOut(300, 'easeOutCubic', function () {
          // After sliding up, remove the 'active' class
          $(this).removeClass('active');
        });
      }
      if (menuDropdown.hasClass('active')) {
        applyStyleWhenAtTop(true);
        dropdownLink.removeClass('open');
        $('.parent-active').removeClass('parent-active');
        $('.current-active').addClass('parent-active');
        $('.menu-overlay').removeClass('show');
        $('.nav-item.open').removeClass('open');
        menuDropdown.fadeOut(300, 'easeOutCubic', function () {
          // After sliding up, remove the 'active' class
          $(this).removeClass('active');
        });
      } else {
        menuDropdown.addClass('active'); // Add the 'active' class
        dropdownLink.addClass('open');
        navItem.addClass('open');
        $('.menu-overlay').addClass('show');
        menuDropdown.fadeIn(300, 'easeOutCubic');
      }
      currentDropdown = menuDropdown;
    });

    // Close all dropdowns and remove active classes when clicking outside
    $('.menu-overlay').on('click', function (event) {
      if (!$(event.target).closest('.menu-dropdown, .dropdown-nav-item').length) {
        closeAllDropdowns();
        $('.parent-active').removeClass('parent-active');
        $('.current-active').addClass('parent-active');
        $('.nav-item').removeClass('open');
        currentDropdown.fadeOut(300, 'easeOutCubic');
        applyStyleWhenAtTop();
      }
    });
    $('.sub-nav-item').on('click', function (event) {
      closeAllDropdowns();
      currentDropdown.fadeOut(300, 'easeOutCubic');
      applyStyleWhenAtTop();
    });
  }

  //Throttle function - prevents too many calls in a certain timeframe

  function throttle(fn, threshhold, scope) {
    threshhold || (threshhold = 250);
    var last, deferTimer;
    return function () {
      var context = scope || this;
      var now = +new Date(),
        args = arguments;
      if (last && now < last + threshhold) {
        // hold on to it
        clearTimeout(deferTimer);
        deferTimer = setTimeout(function () {
          last = now;
          fn.apply(context, args);
        }, threshhold);
      } else {
        last = now;
        fn.apply(context, args);
      }
    };
  }
  var mainLinks = $('.mobile-site-header .menu-main-menu-container .mobile-nav__wrap > li');
  var mainLinksWithChildren = $('.mobile-site-header .menu-main-menu-container>ul>li.menu-item-has-children');
  var mainLinksWithChildrenLinks = $('.mobile-site-header .menu-main-menu-container>ul>li.menu-item-has-children>a');
  var svgForDropdownLinksString = '<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4141 8.9375H0V7.0625H11.4141L6.16406 1.8125L7.5 0.5L15 8L7.5 15.5L6.16406 14.1875L11.4141 8.9375Z" fill="black"/></svg>';

  //Adjusts structure of dropdown nav
  mainLinksWithChildren.each(function () {
    // var title = "<h3 class='submenu-title'><a href='" + $(this).children('a').eq(0).attr('href') + "'>" + $(this).children('a').eq(0).text() + "</a></h3>";
    // var closeButtonIcon = '<svg class="d-lg-block close-button-icon" width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="3.12109" width="34" height="3" rx="1.5" transform="rotate(45 3.12109 0)" fill="#1C1B1F"/><rect x="26.9998" y="2.12134" width="34" height="3" rx="1.5" transform="rotate(135 26.9998 2.12134)" fill="#1C1B1F"/></svg>'
    var closebuttonContent = '<div class="d-lg-none mobile-content"><strong>Main Menu</strong></div>';
    // $(this).append("<div class='dropdown-wrapper'><div class='container'><div class='row title-row'><div class='col-xl-10 offset-xl-1'></div></div><div class='row menu-row'><div class='col-xl-8 offset-xl-1'></div></div></div>");
    // // $(this).children('.dropdown-menu').addClass('col-lg-12 col-xl-10 offset-xl-1');
    $(this).find('.dropdown-wrapper .menu-row>div').prepend($(this).children('.dropdown-menu'));
    // $(this).find('.dropdown-wrapper .title-row').prepend(title);
    $(this).find('.dropdown-wrapper .title-row').append('<div class="close-button flex-row"></div>');
    $(this).find('.close-button').append(svgForDropdownLinksString);
    $(this).find('.close-button').append(closebuttonContent);
    // $(this).find('.close-button').append(closeButtonIcon);

    //Close button controls
    $(this).find('.close-button').click(function (event) {
      event.stopPropagation();
      mainLinksWithChildren.each(function () {
        if (window.innerWidth < 1350) {
          $('.mobile-site-header .menu-main-menu-container').removeClass('slid');
          $(this).removeClass('menu-active');
        } else {
          $(this).removeClass('menu-active');
        }
      });
      dropdownLinks.each(function () {
        $(this).parent().removeClass('menu-active');
      });
      mainLinks.removeClass('submenu-active');
      $('.mobile-site-header').removeClass('menu-open');
    });
  });

  //Open dropdown menu
  mainLinksWithChildrenLinks.each(function () {
    $(this).append(svgForDropdownLinksString);
    $(this).on('click', function (event) {
      event.preventDefault();
      if (window.innerWidth < 1350) {
        $('.mobile-site-header .menu-main-menu-container').addClass('slid');
        mainLinksWithChildren.each(function () {
          $(this).removeClass('menu-active');
        });
        dropdownLinks.each(function () {
          $(this).parent().removeClass('menu-active');
        });
        $(this).parent().addClass('menu-active');
        mainLinks.addClass('submenu-active');
      } else {
        if ($(this).parent().hasClass('menu-active')) {
          $(this).parent().removeClass('menu-active');
          $('.mobile-site-header').removeClass('menu-open');
        } else {
          mainLinksWithChildren.each(function () {
            $(this).removeClass('menu-active');
          });
          dropdownLinks.each(function () {
            $(this).parent().removeClass('menu-active');
          });
          $(this).parent().addClass('menu-active');
          $('.mobile-site-header').addClass('menu-open');
          mainLinks.addClass('submenu-active');
        }
      }
    });
  });

  //Open next level of dropdown menu
  var dropdownLinks = $('.mobile-site-header .menu-main-menu-container>ul>li.menu-item-has-children>.dropdown-wrapper>.container>.menu-row>div>ul>li.menu-item-has-children>a');
  // dropdownLinks = $('.site-header .menu-main-menu-container>ul>li.menu-item-has-children>.dropdown-wrapper');
  dropdownLinks.each(function () {
    $(this).on('click', function (event) {
      if (window.innerWidth >= 1350) {
        event.preventDefault();
        dropdownLinks.each(function () {
          $(this).parent().removeClass('menu-active');
        });
        $(this).parent().addClass('menu-active');
      }
    });
  });

  //Add SVG to links
  var allDropdownLinks = $('.mobile-site-header .menu-main-menu-container>ul>li.menu-item-has-children>.dropdown-wrapper>.container>.menu-row>div>ul>li>a');
  allDropdownLinks.each(function () {
    $(this).append(svgForDropdownLinksString);
  });

  //Adjust structure of second level of dropdown
  var dropdownMenuItems = $('.mobile-site-header .menu-main-menu-container>ul>li.menu-item-has-children>.dropdown-wrapper>.container>.menu-row>div>ul>li.menu-item-has-children');
  dropdownMenuItems.each(function () {
    var linkTitle = $(this).children('a').text();
    var linkURL = $(this).children('a').attr('href');
    var listItem = "<li class='parent-link current-page-parent menu-active' itemscope='itemscope' itemtype='https://www.schema.org/SiteNavigationElement'> <a href='" + linkURL + "' class='dropdown-item'>View " + linkTitle + svgForDropdownLinksString + "</a> </li>";
    var wrapper = "<div class='sub-dropdown-wrapper'></div>";
    $(this).append(wrapper);
    $(this).children('.sub-dropdown-wrapper').append(listItem);
    $(this).children('.sub-dropdown-wrapper').append($(this).children('.dropdown-menu'));
  });
  $('.esg-nav__dropdown-button').click(function () {
    $('.esg-nav__navigation').slideToggle();
    $(this).toggleClass('open');
  });
});